<template>
  <div class="citynews">
    <!-- 头部 -->
    <tobs ref="citynews"></tobs>
    <div class="beititles"><img src="../../../img/batter.png" alt="" /></div>

    <!-- 中间 -->
    <div class="citynews-box">
      <div class="citynews-left">
        <div class="titless"  @click.stop="titleText">{{ title }} </div>
        <div class="ceter" v-html="newsList"></div>
        <div v-if="detailInfo.filesList" class="href-info">
          <a class="href-title" v-for="(item,idx) in detailInfo.filesList" :key='idx'  :href="item.content" target="_blank">{{ item.title }}</a>
        </div>
        <div class="statement">
            声明:该新闻版权归原作者所有，转载目的在于传递更多信息，并不代表本网赞同其观点和对其真实性负责。如涉及作品内容、版权和其它问题,请在30日内与本网联系。

          </div>
      </div>

      <div class="citynews-rit">
        <div class="rit-box">
          <div class="citynews-title">
            <div class="citynews-title1">
              <img src="../../../img/huo.png" alt="" />
              <div class="hor">热门</div>
            </div>
            <div class="dou" @click="content">更多</div>
          </div>
          <div class="xian"></div>
          <div class="news">
            <template v-for="(item, index) in hotColumn">
              <div
                class="imgItem"
                :key="index"
                v-if="index == 0 || index == 1 || index == 2"
                @click="toContent(item.id)"
              >
                <img :src="$imgUrl(item.litimg)" alt="" />

                <div class="imgText">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </template>
            <ul>
              <template v-for="(item, index) in hotColumn">
                <li
                  :key="index"
                  v-if="index == 3 || index == 4 || index == 5 || index == 6"
                  @click="toContent(item.id)"
                >
                  <i></i>
                  <span>{{ item.title }}</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="rit-box">
          <div class="citynews-title">
            <div class="citynews-title1">
              <img src="../../../img/huo.png" alt="" />
              <div class="hor">最新</div>
            </div>
            <div class="dou" @click="content">更多</div>
          </div>
          <div class="xian"></div>
          <div class="news">
            <template v-for="(item, index) in newColumn">
              <div
                class="imgItem"
                :key="index"
                v-if="index == 0 || index == 1 || index == 2"
                @click="toContent(item.id)"
              >
                <img :src="$imgUrl(item.litimg)" alt="" />
                <div class="imgText">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </template>
            <ul>
              <template v-for="(item, index) in newColumn">
                <li
                  :key="index"
                  v-if="index == 3 || index == 4 || index == 5 || index == 6"
                  @click="toContent(item.id)"
                >
                  <i></i>
                  <span>{{ item.title }}</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <foots></foots>
  </div>
</template>

<script>
import tobs from '../../../components/hometitle/hometitle.vue'
import foots from '../../../components/foot/foot.vue'
import { detail, jobNews, jobNewsColumnTree } from '@/api/home/home'
export default {
  components: {
    tobs,
    foots,
  },
  data() {
    return {
      id: this.$route.query.id,
      newsList: [],
      url:'',
      list: {},
      // list1: [],
      title: [],
      source: {},
      imgUrl: '',
      jobNewsColumnList: [], // 新闻栏目
      // imgUrl: '',
      hotId: '',
      newId: '',
      hotColumn: [],
      newColumn: [],
      detailInfo: {},
    }
  },
  computed: {},
  created() {
    this.getJobNewsColumnTree()
    // jobNews({ jobNewsColumnId: '1535162772399075329' }).then((res) => {
    //   console.log(res,'------------------------------')
    //   if (
    //     res.data.rows[0] == null ||
    //     res.data.rows.slice(1, 3) == null ||
    //     res.data.rows.slice(3) == null
    //   ) {
    //   } else {
    //     this.list1 = res.data.rows
    //     this.listt1 = res.data.rows.slice(3)
    //   }
    // })
    detail({ id: this.id }).then((res) => {
      this.detailInfo = res.data
      if(this.detailInfo.files){
        this.detailInfo.filesData = this.detailInfo.files.split('|')
        let data = []
        this.detailInfo.filesData.map(item=>{
          let index = item.lastIndexOf("\/");
          let title = item.substring(index + 1,item.length);
          let obj = {
              title,
              content: item
          }
          data.push(obj)
        })
        this.detailInfo.filesList = data
      }
      this.list = res.data.icon
      this.title = res.data.title
      this.newsList = res.data.content
      res.data.newsType == '1' ? this.url = res.data.target : ''
    })
  },
  mounted() {
    this.$refs.citynews.getindex(0)
  },
  methods: {
    toContent(id) {
      this.$router.push({
	        name: "content",
	        query:{
	          id: id
	        }
        });
    },
    titleText() {
      // window.location.href = this.url;
    },

    getJobNewsColumnTree() {
      jobNewsColumnTree().then((res) => {
        console.log(res, '栏目')
        let column = res.data[0].children
        this.hotId = column.find((i) => i.title == '热门').id
        this.newId = column.find((i) => i.title == '最新').id
        this.getHotColumn()
        this.getNewColumn()
      })
    },

    imgInfo(code) {
      return this.imgUrl + code
    },

    getHotColumn() {
      jobNews({ jobNewsColumnId: this.hotId }).then((res) => {
        this.hotColumn = res.data.rows
        console.log(this.hotColumn)
      })
    },
    getNewColumn() {
      jobNews({ jobNewsColumnId: this.newId }).then((res) => {
        this.newColumn = res.data.rows
        console.log(this.newColumn)
      })
    },
    content() {
      this.$router.push('/newscenter')
    },
  },
}
</script>
<style scoped lang="scss">
.href-info{
  margin-left: 30px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  .href-title{
     color: #000000;
     display: block;
     margin: 10px 0;
    //  text-decoration: none;
  }
}
.statement{
  border-top: 1px solid #CCCCCC;
  font-size: 14px;
  color: #999999;
  box-sizing: border-box;
  padding: 18px 0;
  margin: 0 15px;
}
.titless {
  width: 100%;
  padding: 15px;
  height: 36px;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
  // text-decoration: underline;
}
.ceter {
  padding: 0px 15px;
  min-height: 700px;
}
// tab下的图
.beititles {
  margin-top: 80px;
  width: 100%;
  height: 220px;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.citynews-box {
  display: flex;
  width: 1220px;
  // height: 500px;
  // background: red;
  margin: 20px auto 0;
  justify-content: space-between;
  padding-bottom: 50px;
}

.citynews-left {
  width: 876px;
  height: 100%;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
}

.citynews-rit {
  width: 303px;
  height: 100%;
  .rit-box {
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .news {
    padding: 10px 20px 20px 20px;
    .imgItem {
      width: 100%;
      height: 146px;
      position: relative;
      margin-bottom: 10px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .imgText {
        height: 38px;
        line-height: 38px;
      }
    }
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul {
    li {
      display: flex;
      align-items: center;
      padding: 5px 0;
      color: #666;
      i {
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #ccc;
        margin-right: 6px;
      }
      span {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
      span:hover {
        text-decoration: underline;
      }
    }
  }

  .imgText {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);

    span {
      color: #fff;
      margin-left: 10px;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .rit-box1 {
    // height: 200px;
    height: 100%;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 5px;
  }
}

// 右边可动数据
.tabimg {
  display: block;
  margin: 0 auto;
  width: 90%;
  height: 152px;
  margin-bottom: 6px;
  margin-top: 10px;
}

.list-box {
  display: flex;
  margin-left: 18px;
}

.box {
  width: 4px;
  height: 4px;
  background: rgba(204, 204, 204, 1);
  margin-top: 10px;
  margin-right: 9px;
}

.source {
  width: 254px;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// 右边
.citynews-title {
  display: flex;
  height: 44px;
  justify-content: space-between;
  padding: 12px 12px 0 12px;
  .citynews-title1 {
    display: flex;
  }
  img {
    display: block;
    width: 18px;
    height: 18px;
  }
  .hor {
    color: rgba(2, 154, 255, 1);
    font-size: 16px;
    margin-left: 8px;
  }
  .dou {
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
  }
}
.xian {
  width: 301px;
  height: 0px;
  background: rgba(204, 204, 204, 1);
  border: 1px solid rgba(242, 242, 242, 1);
}
</style>
